
import { cn } from "@/lib/utils";
import { homeAction, homeState, promptResponse } from "@state/home";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { Stepper } from 'react-form-stepper';
import { useSnapshot } from "valtio";
import EnterPrompt from "./subs/EnterPrompt";
import SelectProduct from "./subs/SelectProduct";

export default function CreateDesignBlock({ auth, samplePrompt }) {

    const snapHome = useSnapshot(homeState);
    const setResponse = useSetAtom(promptResponse);

    const steps = [
        {
            label: <div data-aos="fade-up" className="flex flex-col cursor-pointer group" onClick={() => homeAction.setActiveStep(0)}>
                <span className={cn("md:text-sm text-xs font-semibold font-nunito group-hover:underline italic", snapHome.activeStep !== 0 ? 'text-muted-foreground' : "")}>Design Your Graphics</span>
                <small className={cn("font-semibold md:block hidden truncate", snapHome.activeStep !== 0 ? 'text-muted-foreground' : "")}>Enter your design prompt and select from result</small>
            </div>,
            completed: snapHome.activeStep > 0
        },
        {
            label: <div data-aos="fade-up" className="flex flex-col cursor-not-allowed">
                <span className={cn("md:text-sm text-xs font-semibold font-nunito italic", snapHome.activeStep !== 1 ? 'text-muted-foreground' : "")}>Pick Your Product</span>
                <small className={cn("font-semibold md:block hidden truncate", snapHome.activeStep !== 1 ? 'text-muted-foreground' : "")}>Select which product you want to print</small>
            </div>,
            completed: snapHome.activeStep > 1
        },
        {
            label: <div data-aos="fade-up" className="flex flex-col cursor-not-allowed">
                <span className={cn("md:text-sm text-xs font-semibold font-nunito italic", snapHome.activeStep !== 2 ? 'text-muted-foreground' : "")}>Place Your Order</span>
                <small className={cn("font-semibold md:block hidden truncate", snapHome.activeStep !== 2 ? 'text-muted-foreground' : "")}>Place your order details</small>
            </div>,
            completed: false
        }
    ];

    useEffect(() => {
        setResponse(samplePrompt);
        homeAction.setActiveStep(0);
    }, [samplePrompt])

    return (
        <div className="w-screen h-full py-6 bg-muted">
            <div className="max-w-7xl w-full mx-auto">
                <div className="flex flex-col justify-center py-6 items-center w-full">
                    <h1 data-aos="fade-down" className="md:text-3xl text-lg mb-4 text-center font-semibold font-nunito italic text-primary uppercase">
                        Create Your Own Design in 3 Steps
                    </h1>
                </div>

                <div className="w-full flex flex-col gap-4">
                    <Stepper
                        steps={steps}
                        activeStep={snapHome.activeStep}
                        styleConfig={{
                            completedBgColor: 'teal',
                            completedColor: 'teal',
                            activeBgColor: 'teal',
                            inactiveBgColor: 'black'
                        }}
                    />
                    {snapHome.activeStep === 0 && <EnterPrompt auth={auth} />}
                    {snapHome.activeStep === 1 && <SelectProduct />}
                </div>
            </div>
        </div>
    );
}
